<template>
  <v-menu offset-y left nudge-bottom="14" min-width="300px" max-height="350px">
    <template v-slot:activator="{ on, attrs }">
      <v-badge :content="notifications.length" :value="notifications.length" color="error" overlap class="ms-3">
        <v-btn icon small v-bind="attrs" v-on="on">
          <v-icon> mdi-bell-outline </v-icon>
        </v-btn>
      </v-badge>
    </template>
    <v-list class="pb-6">
      <v-subheader class="font-weiight-bold">
        <v-icon small class="mr-1">mdi-bell-ring-outline</v-icon>
        Notificações
      </v-subheader>
      <template v-for="(notification, index) in notifications">
        <v-divider v-if="index != 1" :key="index" class="my-2"></v-divider>
        <component :is="notification.type" :data="notification" :key="`${notification.type}${index}`" />
      </template>
    </v-list>
  </v-menu>
</template>

<script>
import NOTIFICATION from "@/services/notification";
import PaymentNotification from "./notification/PaymentNotification.vue";

export default {
  components: { Payment: PaymentNotification },
  data: () => ({
    notifications: [],
    interval: null
  }),
  methods: {
    async getNotifications() {
      const response = await NOTIFICATION.getAll();
      this.notifications = response.notifications;
    }
  },
  mounted() {
    this.getNotifications();
    this.$root.$on("refresh-notification", () => {
      this.getNotifications();
    });
    this.interval = setInterval(() => {
      this.getNotifications();
    }, 1000 * 60 * 10);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  }
};
</script>

<style lang="scss" scoped></style>
