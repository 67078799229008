import http from "@/http-common";

const getAll = async () => {
  return await http
    .get("/rental")
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error;
    });
};

const getOneById = async id => {
  return await http
    .get("/rental/" + id)
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error;
    });
};

const getBoleto = async (rentalId, paymentId) => {
  return await http
    .get(`/rental/${rentalId}/payment/${paymentId}/boleto`)
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error;
    });
};

export default {
  getAll,
  getOneById,
  getBoleto
};
