import { render, staticRenderFns } from "./AppBarUserMenu.vue?vue&type=template&id=37641344&scoped=true"
import script from "./AppBarUserMenu.vue?vue&type=script&lang=js"
export * from "./AppBarUserMenu.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37641344",
  null
  
)

export default component.exports