<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="260"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link to="/dashboard" class="d-flex align-center text-decoration-none">
        <v-img
          :src="require(`@/assets/images/logos/logo-${$vuetify.theme.dark ? 'dark' : 'light'}.svg`)"
          max-height="auto"
          max-width="200px"
          alt="logo"
          contain
          eager
          class="app-logo"
        ></v-img>
      </router-link>
    </div>

    <v-list expand link class="vertical-nav-menu-items pr-5">
      <v-list-item
        :to="{ name: 'dashboard' }"
        class="vertical-nav-menu-link"
        v-bind="$attrs"
        active-class="bg-gradient-primary white--text"
      >
        <v-list-item-icon>
          <v-icon class="mx-auto">mdi-view-dashboard</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Painel Inicial</v-list-item-title>
      </v-list-item>

      <template v-if="rentals.length">
        <nav-menu-section-title v-if="rentals.length" title="MINHAS LOCAÇÕES" class="mt-1"></nav-menu-section-title>
        <v-list-item
          v-for="rental in rentals"
          :key="rental.id"
          :to="{ name: 'rental', params: { id: rental.id } }"
          class="vertical-nav-menu-link"
          v-bind="$attrs"
          active-class="bg-gradient-primary white--text"
        >
          <v-list-item-icon>
            <v-icon class="mx-auto">mdi-home-city-outline</v-icon>
            <v-badge overlap dot :color="rental.payments == 'Adimplente' ? 'success' : 'error'" offset-x="3"></v-badge>
          </v-list-item-icon>
          <v-list-item-title>ID: {{ rental.id.slice(0, 8) }}</v-list-item-title>
        </v-list-item>

        <p class="text-xs text-center text--secondary mt-4">
          <v-icon x-small color="success">mdi-circle</v-icon> Adimplente |
          <v-icon x-small color="error">mdi-circle</v-icon> Inadimplente
        </p>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import NavMenuSectionTitle from "./components/NavMenuSectionTitle.vue";
import NavMenuGroup from "./components/NavMenuGroup.vue";
import NavMenuLink from "./components/NavMenuLink.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    NavMenuSectionTitle,
    NavMenuGroup,
    NavMenuLink
  },
  methods: {
    ...mapActions("rental", ["getAll"])
  },
  computed: {
    ...mapGetters("rental", ["rentals"])
  },
  async mounted() {
    try {
      await this.getAll();
    } catch (e) {
      console.log(e);
      this.$toast.fire({
        title: "Erro ao carregar os dados",
        icon: "error",
        html: e.message
      });
    }
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null
    }
  }
};
</script>

<style lang="scss" scoped>
@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, "background");
}
.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}
</style>
