<template>
  <v-list-item class="py-8">
    <v-list-item-avatar>
      <v-icon large>mdi-barcode</v-icon>
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title class="font-weight-bold">Novo boleto!</v-list-item-title>
      <v-list-item-subtitle class="text-wrap">
        Valor de <b>{{ data.payment.value | currency }}</b> com vencimento em:
        <span class="font-weight-bold error--text">{{ data.payment.validity | date }}</span>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-btn icon @click="go">
        <v-icon color="grey lighten-1">mdi-arrow-top-right-bold-box-outline</v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import moment from "moment";
export default {
  methods: {
    go() {
      // if (this.$route.name !== "rental" || this.$route.params.id !== this.data.rental.id)
      //   this.$router.push({ name: "rental", params: { id: this.data.rental.id }, hash: "#payments" });
      // else if (this.$route.hash != "#payments") this.$router.push({ hash: "#payments" });
      // this.$root.$emit("payment-notification");
      this.$router.push({ name: "rental", params: { id: this.data.rental.id }, hash: "#payments" }).catch(() => {});
      this.$root.$emit("payment-notification");
    }
  },
  filters: {
    currency(value) {
      value = parseFloat(value);
      return value.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2
      });
    },
    date(value) {
      return moment(value).format("DD/MM");
    }
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  }
};
</script>

<style></style>
