import Vue from "vue";
import Vuex from "vuex";
import auth from "@/services/auth";

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    token: null,
    user: null
  },
  getters: {
    isAuthenticated: state => state.token,
    user: state => state.user
  },
  mutations: {
    setLogin(state, { token, user }) {
      state.token = token;
      state.user = user;
    }
  },
  actions: {
    async login({ commit }, data) {
      let response = await auth.login(data);
      commit("setLogin", response);
      return response;
    },
    async renewToken({ commit }) {
      try {
        let response = await auth.renewToken();
        commit("setLogin", response);
        return response;
      } catch(e) {
        commit("setLogin", { token: null, user: null });
        return false;
      }
    },
    logout({ commit }) {
      commit("setLogin", { token: null, user: null });
    }
  }
};
