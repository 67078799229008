import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  // Módulos
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("@/views/dashboard/Dashboard.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/dashboard/meus-dados",
    name: "user-profile",
    component: () => import("@/views/user-profile/UserProfile.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/dashboard/locacao/",
    name: "rental.index",
    redirect: "/dashboard",
    meta: { requiresAuth: true }
  },
  {
    path: "/dashboard/locacao/:id",
    name: "rental",
    component: () => import("@/views/locations/Locations.vue"),
    meta: { requiresAuth: true }
  },

  // Acessos
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/access/Login.vue"),
    meta: {
      layout: "blank"
    }
  },
  {
    path: "/recuperar-senha",
    name: "recover-password",
    component: () => import("@/views/access/RecoverPassword.vue"),
    meta: {
      layout: "blank"
    }
  },
  {
    path: "/alterar-senha",
    name: "change-password",
    component: () => import("@/views/access/ChangePassword.vue"),
    meta: {
      layout: "blank"
    }
  },
  // Comum
  {
    path: "/",
    redirect: "login"
  },
  {
    path: "/erro",
    name: "error-404",
    component: () => import("@/views/common/Error.vue"),
    meta: {
      layout: "blank"
    }
  },
  {
    path: "*",
    redirect: "error-404"
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters["auth/isAuthenticated"]) {
      return next({
        path: "/login",
        query: { redirect: to.fullPath }
      });
    }
  }
  next();
});

export default router;
