require('./overrides.scss')

export default {
  theme: {
    dark: true,
    themes: {
      light: {
        primary: '#5C5959',
        primarylight: '#ACAEB2',
        accent: '#6B41F5',
        secondary: '#8A8D93',
        success: '#56CA00',
        info: '#45C2EC',
        warning: '#FFB93E',
        error: '#F4575D',
      },
      dark: {
        primary: '#757070',
        primarylight: '#ACAEB2',
        accent: '#6B41F5',
        secondary: '#8A8D93',
        success: '#56CA00',
        info: '#45C2EC',
        warning: '#FFB93E',
        error: '#F4575D',
      },
    },
  },
}