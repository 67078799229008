<template>
  <v-app>
    <vertical-nav-menu v-if="!loading" :is-drawer-open.sync="isDrawerOpen"></vertical-nav-menu>

    <v-app-bar app flat absolute color="transparent">
      <div class="boxed-container w-full">
        <div class="d-flex align-center mx-6">
          <v-app-bar-nav-icon class="d-block d-lg-none me-2" @click="isDrawerOpen = !isDrawerOpen"></v-app-bar-nav-icon>

          <v-spacer></v-spacer>

          <theme-switcher></theme-switcher>
          <app-bar-notification></app-bar-notification>
          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
    </v-app-bar>

    <v-main>
      <v-overlay :value="loading"></v-overlay>
      <div v-if="!loading" class="app-content-container boxed-container pt-1 pb-4 px-6">
        <router-view></router-view>
      </div>
    </v-main>

    <v-footer app inset color="transparent" absolute height="56" class="px-0 pb-6">
      <div class="boxed-container w-full">
        <v-row class="mx-6 d-flex">
          <v-col cols="12" md="8" class="text-left pl-0 d-none d-sm-inline">
            &copy; 2022
            <a href="https://daher.adm.br/" class="text-decoration-none" target="_blank">
              C.Daher Empreendimentos e Participações
            </a>
          </v-col>
          <v-col cols="12" md="4" class="text-right pr-0">
            <a href="mailto:contato@daher.adm.br" class="me-6 text--secondary text-decoration-none">Fale Conosco</a>
            <a href="tel:+554333222119" class="text--secondary text-decoration-none">+55 43 3322-2119</a>
          </v-col>
        </v-row>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import VerticalNavMenu from "./components/vertical-nav-menu/VerticalNavMenu.vue";
import ThemeSwitcher from "./components/ThemeSwitcher.vue";
import AppBarUserMenu from "./components/AppBarUserMenu.vue";
import AppBarNotification from "./components/AppBarNotification.vue";
import { mapActions } from "vuex";
export default {
  data: () => ({
    isDrawerOpen: null,
    interval: null,
    loading: true
  }),
  methods: {
    ...mapActions("auth", ["renewToken"]),
    async rToken() {
      let response = await this.renewToken();
      if (response) return (this.loading = false);
      let currentUrl = this.$route.fullPath;
      this.$router.push({
        path: "/login",
        query: { redirect: currentUrl }
      });
      this.$toast.fire({
        icon: "error",
        title: "Sua sessão expirou, por favor faça login novamente."
      });
    }
  },
  mounted() {
    this.rToken();
    this.interval = setInterval(() => {
      this.rToken();
    }, 60000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  components: {
    VerticalNavMenu,
    ThemeSwitcher,
    AppBarUserMenu,
    AppBarNotification
  }
};
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;
  }
}

.boxed-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}
</style>
