<template>
  <component :is="resolveLayout"></component>
</template>

<script>
import { mapState } from 'vuex'
import LayoutBlank from '@/layouts/Blank.vue'
import LayoutContent from '@/layouts/Content.vue'

export default {
  components: {
    LayoutBlank,
    LayoutContent,
  },
  data() {
    return {
      resolveLayout: null,
    }
  },
  watch: {
    '$route'(to, from) {
      this.determineLayout()
    }
  },
  methods: {
    determineLayout() {
      const route = this.$route
      if (route.name === null) {
        this.resolveLayout = null
      } else if (route.meta.layout === 'blank') {
        this.resolveLayout = 'LayoutBlank'
      } else {
        this.resolveLayout = 'LayoutContent'
      }
    }
  },
  created() {
    this.determineLayout()
  },
}
</script>